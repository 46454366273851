<template>
  <div class="SetBox">


    <el-form ref="form" :model="value" label-width="80px">


      <el-col :span="9">
        <el-form-item label="微信AppKey" prop="title">
          <el-input v-model="value.name" placeholder="请输入微信AppKey" />
        </el-form-item>

        <el-form-item label="微信AppSecret" prop="title">
          <el-input v-model="value.mail" placeholder="请输入微信AppSecret" />
        </el-form-item>




        <el-form-item>
          <el-button type="primary" @click="onSubmit" size="mini">保存</el-button>
        </el-form-item>

      </el-col>

    </el-form>



  </div>
</template>

<script>
  export default {
    components: {

    },

    // model: {
    //   prop: 'value',
    //   event: 'change'
    // },
    // props: {
    //   value: {
    //     type: Object,
    //     required: true
    //   }
    // },

    data() {
      return {

        value: {
          name: "",
          mail: "",

        },

      }
    },
    methods: {
      onSubmit() {
        console.log('submit!');
      }

    }

  }
</script>

<style>

</style>
