<template>
    <div class="SetBox">


        <el-form ref="form" :model="form" label-width="80px">



            <el-form-item label="网站logo">
                <el-upload list-type="picture-card" :on-preview="handlePreview" :on-remove="handlePreview"
                    :before-remove="beforeRemove" :on-change="uploadSectionFile" drag action class="SelfUploadBox"
                    style="margin: 0;" :limit="1" ref="businessLicense" :auto-upload="false">
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">将文件拖拽到此处或点击上传</div>
                </el-upload>
            </el-form-item>



            <el-row>

                <el-col :span="9">
                    <el-form-item label="网站名称">
                        <el-input v-model="form.name" placeholder="请输入网站名称"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="9">
                    <el-form-item label="网站网址">
                        <el-input v-model="form.name" placeholder="请输入网址"></el-input>
                    </el-form-item>
                </el-col>

            </el-row>

            <el-row>

                <el-col :span="9">
                    <el-form-item label="联系地址">
                        <el-input v-model="form.name" placeholder="请输入联系地址"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="9">
                    <el-form-item label="联系电话">
                        <el-input v-model="form.name" placeholder="请输入联系电话"></el-input>
                    </el-form-item>
                </el-col>

            </el-row>

            <el-row>

                <el-col :span="9">
                    <el-form-item label="邮政编码">
                        <el-input v-model="form.name" placeholder="请输入邮政编码"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="9">
                    <el-form-item label="电子邮箱">
                        <el-input v-model="form.name" placeholder="请输入电子邮箱"></el-input>
                    </el-form-item>
                </el-col>

            </el-row>

            <el-row>
                <el-col :span="9">
                    <el-form-item label="备案编号">
                        <el-input v-model="form.name" placeholder="请输入备案编号" />
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row>
                <el-col :span="9">
                    <el-form-item label="注册日期">
                        <el-date-picker v-model="form.value1" :key="form.value1" type="date" placeholder="请选择注册日期" />
                    </el-form-item>
                </el-col>
                <el-col :span="9">
                    <el-form-item label="注册时间">
                        <el-time-select v-model="form.value2" :key="form.value2" placeholder="请选择注册时间"
                            :picker-options="{start: '08:00',step: '01:00',end: '18:00'}" />
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row>

                <el-col :span="100">
                    <el-form-item label="行政区域">

                        <el-checkbox-group v-model="form.checkList">
                            <el-checkbox label="复选框 A" />
                            <el-checkbox label="复选框 B" />
                            <el-checkbox label="复选框 C" />
                            <el-checkbox label="复选框 D" />
                            <el-checkbox label="复选框 E" />
                            <el-checkbox label="复选框 F" />
                            <el-checkbox label="复选框 G" />
                            <el-checkbox label="复选框 H" />
                        </el-checkbox-group>

                    </el-form-item>
                </el-col>

            </el-row>




            <el-row>

                <el-col :span="9">
                    <el-form-item label="下拉选项">
                        <el-select v-model="form.region" placeholder="请选择活动区域">
                            <el-option label="区域一" value="shanghai"></el-option>
                            <el-option label="区域二" value="beijing"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

            </el-row>




            <el-row>

                <el-col :span="100">
                    <el-form-item label="行政区域">

                        <el-radio-group v-model="form.radio">
                            <el-radio :label="1">备选项1</el-radio>
                            <el-radio :label="2">备选项2</el-radio>
                            <el-radio :label="3">备选项3</el-radio>
                            <el-radio :label="4">备选项4</el-radio>
                            <el-radio :label="5">备选项5</el-radio>
                            <el-radio :label="6">备选项6</el-radio>
                            <el-radio :label="7">备选项7</el-radio>
                            <el-radio :label="8">备选项8</el-radio>
                            <el-radio :label="9">备选项9</el-radio>
                        </el-radio-group>

                    </el-form-item>
                </el-col>

            </el-row>

            <el-form-item>
                <el-button type="primary" @click="onSubmit" size="mini">保存</el-button>
            </el-form-item>

        </el-form>


    </div>
</template>

<script>
    export default {
        components: {

        },

        data() {
            return {
                form: {
                    name: '',
                    region: '',
                    date1: '',
                    date2: '',
                    delivery: false,
                    type: [],
                    resource: '',
                    desc: '',

                    value1: "",
                    value2: "",
                    checkList: [],
                    radio: 1,

                    // 图片的路径
                    dialogImageUrl: "",
                },

            }
        },
        methods: {


            open() {
                this.formShow = true
                this.show = true
            },
            refresh() {
                this.show = false
                this.$emit('refresh')
                setTimeout(() => {
                    this.formShow = false
                }, 1000)
            },

            uploadSectionFile(file) {
                // 图片的路径
                this.dialogImageUrl = file.url;
                console.log("111111" + file.url);
            },

            beforeRemove(file) {
                // 图片的路径
                this.dialogImageUrl = file.url;
                console.log("22222" + file.url);
            },

            handlePreview(file) {
                // 图片的路径
                this.dialogImageUrl = file.url;
                console.log("33333" + file.url);
            },

            onSubmit() {
                console.log('submit!');
            }

        }

    }
</script>

<style>
    .SetBox {
        /* padding: 30px; */
        /* border: 1px red dotted;
        box-sizing: border-box; */
    }

    .SetBox .el-date-editor.el-input,
    .SetBox .el-date-editor.el-input__inner,
    .SetBox .el-select.el-select--mini {

        /* border: 1px red dotted;
    box-sizing: border-box; */
        width: 100%;

    }
</style>