<template>

    <div class="level_index level_indexI">

        <el-tabs v-model="activeName" @tab-click="handleClick">

            <el-tab-pane label="基本设置" name="001">
                <Basic></Basic>
            </el-tab-pane>
            <el-tab-pane label="邮件设置" name="002">
                <Mail></Mail>
            </el-tab-pane>
            <el-tab-pane label="微信设置" name="003">
                <WeChat></WeChat>
            </el-tab-pane>
            <el-tab-pane label="短信设置" name="004">
                <Message></Message>
            </el-tab-pane>

        </el-tabs>


    </div>

</template>

<script>
    import Basic from './Basic.vue'
    import Mail from './Mail.vue'
    import WeChat from './WeChat.vue'
    import Message from './Message.vue'



    export default {
        components: {
            Basic,
            Mail,
            WeChat,
            Message

        },
        data() {
            return {
                activeName: '001',
            }
        },


        methods: {
            handleClick(tab, event) {
                console.log(tab.index);
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>
