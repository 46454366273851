import { render, staticRenderFns } from "./SetUp.vue?vue&type=template&id=a2d5a7a6&scoped=true&"
import script from "./SetUp.vue?vue&type=script&lang=js&"
export * from "./SetUp.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a2d5a7a6",
  null
  
)

export default component.exports